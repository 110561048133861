import { default as _401HuBYTBGl8SMeta } from "/home/runner/work/portal2-frontend/portal2-frontend/pages/401.vue?macro=true";
import { default as change_45passwordXJkAztILXHMeta } from "/home/runner/work/portal2-frontend/portal2-frontend/pages/account/change-password.vue?macro=true";
import { default as indexAM1HeT8I7EMeta } from "/home/runner/work/portal2-frontend/portal2-frontend/pages/account/index.vue?macro=true";
import { default as notificationsLPc0GsfyhwMeta } from "/home/runner/work/portal2-frontend/portal2-frontend/pages/account/notifications.vue?macro=true";
import { default as preferencesAyAvwQhEdQMeta } from "/home/runner/work/portal2-frontend/portal2-frontend/pages/account/preferences.vue?macro=true";
import { default as accountiA8tX7zHzAMeta } from "/home/runner/work/portal2-frontend/portal2-frontend/pages/account.vue?macro=true";
import { default as _91invoiceid_93sDe98PfSvMMeta } from "/home/runner/work/portal2-frontend/portal2-frontend/pages/accounting/invoice/[invoiceid].vue?macro=true";
import { default as _91postageid_93rPJ5kaRQs4Meta } from "/home/runner/work/portal2-frontend/portal2-frontend/pages/accounting/invoice/[serviceid]/[postageid].vue?macro=true";
import { default as indexHP1BF5rLryMeta } from "/home/runner/work/portal2-frontend/portal2-frontend/pages/accounting/invoice/index.vue?macro=true";
import { default as newinvoicebGpKziYCaFMeta } from "/home/runner/work/portal2-frontend/portal2-frontend/pages/accounting/invoice/newinvoice.vue?macro=true";
import { default as companyxArZtrXaIeMeta } from "/home/runner/work/portal2-frontend/portal2-frontend/pages/auth/company.vue?macro=true";
import { default as indexnlV6iX0qoHMeta } from "/home/runner/work/portal2-frontend/portal2-frontend/pages/auth/confirm/email/[email]/index.vue?macro=true";
import { default as indext5JuSlxLEHMeta } from "/home/runner/work/portal2-frontend/portal2-frontend/pages/auth/confirm/index.vue?macro=true";
import { default as indexQcRMlBhBIrMeta } from "/home/runner/work/portal2-frontend/portal2-frontend/pages/auth/reset-password/email/[email]/code/[code]/index.vue?macro=true";
import { default as indexO2zWEAP92lMeta } from "/home/runner/work/portal2-frontend/portal2-frontend/pages/auth/reset-password/email/[email]/index.vue?macro=true";
import { default as indexkqq8cNW3VLMeta } from "/home/runner/work/portal2-frontend/portal2-frontend/pages/auth/reset-password/index.vue?macro=true";
import { default as campaignsSQ2Sx6oUh4Meta } from "/home/runner/work/portal2-frontend/portal2-frontend/pages/campaigns.vue?macro=true";
import { default as campaignSupportj7IQMj2GMXMeta } from "/home/runner/work/portal2-frontend/portal2-frontend/pages/campaignSupport.vue?macro=true";
import { default as _91folderName_9392y8yW3K2yMeta } from "/home/runner/work/portal2-frontend/portal2-frontend/pages/company/[companyId]/assets/[folderName].vue?macro=true";
import { default as indexyitrjj7pS5Meta } from "/home/runner/work/portal2-frontend/portal2-frontend/pages/company/[companyId]/assets/index.vue?macro=true";
import { default as billingKCHhEFiEpHMeta } from "/home/runner/work/portal2-frontend/portal2-frontend/pages/company/[companyId]/billing.vue?macro=true";
import { default as indexEn74J9ghxNMeta } from "/home/runner/work/portal2-frontend/portal2-frontend/pages/company/[companyId]/campaigns/[campaignId]/index.vue?macro=true";
import { default as createAycqmafRMeMeta } from "/home/runner/work/portal2-frontend/portal2-frontend/pages/company/[companyId]/campaigns/create.vue?macro=true";
import { default as indexvC92XIce6PMeta } from "/home/runner/work/portal2-frontend/portal2-frontend/pages/company/[companyId]/campaigns/index.vue?macro=true";
import { default as campaignSupportTjTmMfI9twMeta } from "/home/runner/work/portal2-frontend/portal2-frontend/pages/company/[companyId]/campaignSupport.vue?macro=true";
import { default as campaignSupports7ZofAqOQDcMeta } from "/home/runner/work/portal2-frontend/portal2-frontend/pages/company/[companyId]/campaignSupports.vue?macro=true";
import { default as creditsTpWqV4Qu1tMeta } from "/home/runner/work/portal2-frontend/portal2-frontend/pages/company/[companyId]/credits.vue?macro=true";
import { default as discountsBqDGpwt3qMMeta } from "/home/runner/work/portal2-frontend/portal2-frontend/pages/company/[companyId]/discounts.vue?macro=true";
import { default as historyGQInVQOV9JMeta } from "/home/runner/work/portal2-frontend/portal2-frontend/pages/company/[companyId]/history.vue?macro=true";
import { default as indexsdesMEjlzoMeta } from "/home/runner/work/portal2-frontend/portal2-frontend/pages/company/[companyId]/index.vue?macro=true";
import { default as indexFWOffo7bEnMeta } from "/home/runner/work/portal2-frontend/portal2-frontend/pages/company/[companyId]/lists/[listId]/file/[listFileId]/index.vue?macro=true";
import { default as resultsIbEAWtkcSpMeta } from "/home/runner/work/portal2-frontend/portal2-frontend/pages/company/[companyId]/lists/[listId]/file/[listFileId]/results.vue?macro=true";
import { default as indexPk1pzpWkU4Meta } from "/home/runner/work/portal2-frontend/portal2-frontend/pages/company/[companyId]/lists/[listId]/index.vue?macro=true";
import { default as uploadeoQl8dKBk9Meta } from "/home/runner/work/portal2-frontend/portal2-frontend/pages/company/[companyId]/lists/[listId]/upload.vue?macro=true";
import { default as createCTg7S0RhFUMeta } from "/home/runner/work/portal2-frontend/portal2-frontend/pages/company/[companyId]/lists/create.vue?macro=true";
import { default as index6cQkwK7w8qMeta } from "/home/runner/work/portal2-frontend/portal2-frontend/pages/company/[companyId]/lists/index.vue?macro=true";
import { default as index84xc2K066oMeta } from "/home/runner/work/portal2-frontend/portal2-frontend/pages/company/[companyId]/locations/[locationId]/index.vue?macro=true";
import { default as indexaI4L7xSDhjMeta } from "/home/runner/work/portal2-frontend/portal2-frontend/pages/company/[companyId]/locations/index.vue?macro=true";
import { default as locationsPyiNH0SfRCMeta } from "/home/runner/work/portal2-frontend/portal2-frontend/pages/company/[companyId]/locations.vue?macro=true";
import { default as settingsvYn7oXJITQMeta } from "/home/runner/work/portal2-frontend/portal2-frontend/pages/company/[companyId]/settings.vue?macro=true";
import { default as indexfy3AvcgAURMeta } from "/home/runner/work/portal2-frontend/portal2-frontend/pages/company/[companyId]/users/index.vue?macro=true";
import { default as users3qyHyfvhTOMeta } from "/home/runner/work/portal2-frontend/portal2-frontend/pages/company/[companyId]/users.vue?macro=true";
import { default as create1bHsoA9rkQMeta } from "/home/runner/work/portal2-frontend/portal2-frontend/pages/company/create.vue?macro=true";
import { default as indexKNIQeTNKuHMeta } from "/home/runner/work/portal2-frontend/portal2-frontend/pages/company/index.vue?macro=true";
import { default as companyAuRePIrUpeMeta } from "/home/runner/work/portal2-frontend/portal2-frontend/pages/company.vue?macro=true";
import { default as faqTfHNXIdp0DMeta } from "/home/runner/work/portal2-frontend/portal2-frontend/pages/faq.vue?macro=true";
import { default as forgotPasswordxDthygcoYfMeta } from "/home/runner/work/portal2-frontend/portal2-frontend/pages/forgotPassword.vue?macro=true";
import { default as indexkjDU6sW8zfMeta } from "/home/runner/work/portal2-frontend/portal2-frontend/pages/index.vue?macro=true";
import { default as lists9bZHGmCfWVMeta } from "/home/runner/work/portal2-frontend/portal2-frontend/pages/lists.vue?macro=true";
import { default as loginhmR34h9WdkMeta } from "/home/runner/work/portal2-frontend/portal2-frontend/pages/login.vue?macro=true";
import { default as privacyJJfR35u8BuMeta } from "/home/runner/work/portal2-frontend/portal2-frontend/pages/privacy.vue?macro=true";
import { default as indexJzQxqcWzHaMeta } from "/home/runner/work/portal2-frontend/portal2-frontend/pages/products/[productid]/index.vue?macro=true";
import { default as categoriesKffWUczpbtMeta } from "/home/runner/work/portal2-frontend/portal2-frontend/pages/products/categories.vue?macro=true";
import { default as envelopesDVOVOGee5wMeta } from "/home/runner/work/portal2-frontend/portal2-frontend/pages/products/envelopes.vue?macro=true";
import { default as indexxZHcSBR8E3Meta } from "/home/runner/work/portal2-frontend/portal2-frontend/pages/products/index.vue?macro=true";
import { default as letterShop0W7jUCJkKTMeta } from "/home/runner/work/portal2-frontend/portal2-frontend/pages/products/letterShop.vue?macro=true";
import { default as paperStock5CX0M3nfdSMeta } from "/home/runner/work/portal2-frontend/portal2-frontend/pages/products/paperStock.vue?macro=true";
import { default as productHistoryejNffZYlLKMeta } from "/home/runner/work/portal2-frontend/portal2-frontend/pages/products/productHistory.vue?macro=true";
import { default as registeroQ9uB8TWNHMeta } from "/home/runner/work/portal2-frontend/portal2-frontend/pages/register.vue?macro=true";
import { default as indexyXNwuXzzaYMeta } from "/home/runner/work/portal2-frontend/portal2-frontend/pages/reports/index.vue?macro=true";
import { default as brandsgr4HO3MRELMeta } from "/home/runner/work/portal2-frontend/portal2-frontend/pages/settings/brands.vue?macro=true";
import { default as indexcvvqtO5YChMeta } from "/home/runner/work/portal2-frontend/portal2-frontend/pages/settings/campaignType/[campaignTypeId]/index.vue?macro=true";
import { default as index1n8J2GVuX3Meta } from "/home/runner/work/portal2-frontend/portal2-frontend/pages/settings/campaignType/index.vue?macro=true";
import { default as creditsvSggMupk5fMeta } from "/home/runner/work/portal2-frontend/portal2-frontend/pages/settings/credits.vue?macro=true";
import { default as discountsHdA58cFX32Meta } from "/home/runner/work/portal2-frontend/portal2-frontend/pages/settings/discounts.vue?macro=true";
import { default as index1FI7MCilt2Meta } from "/home/runner/work/portal2-frontend/portal2-frontend/pages/settings/index.vue?macro=true";
import { default as jobIntegrationsKqZw0Yi6niMeta } from "/home/runner/work/portal2-frontend/portal2-frontend/pages/settings/jobIntegrations.vue?macro=true";
import { default as supportEF6SwxkgDpMeta } from "/home/runner/work/portal2-frontend/portal2-frontend/pages/support.vue?macro=true";
import { default as termsCmQpDs8HqQMeta } from "/home/runner/work/portal2-frontend/portal2-frontend/pages/terms.vue?macro=true";
import { default as clientwEeptjs88iMeta } from "/home/runner/work/portal2-frontend/portal2-frontend/pages/users/client.vue?macro=true";
import { default as indexButVxqY1YhMeta } from "/home/runner/work/portal2-frontend/portal2-frontend/pages/users/index.vue?macro=true";
import { default as internalZs2aMpzH39Meta } from "/home/runner/work/portal2-frontend/portal2-frontend/pages/users/internal.vue?macro=true";
import { default as users8AaY5h6Z4qMeta } from "/home/runner/work/portal2-frontend/portal2-frontend/pages/users.vue?macro=true";
import { default as versionKeC3GHNQEuMeta } from "/home/runner/work/portal2-frontend/portal2-frontend/pages/version.vue?macro=true";
export default [
  {
    name: _401HuBYTBGl8SMeta?.name ?? "401",
    path: _401HuBYTBGl8SMeta?.path ?? "/401",
    meta: _401HuBYTBGl8SMeta || {},
    alias: _401HuBYTBGl8SMeta?.alias || [],
    redirect: _401HuBYTBGl8SMeta?.redirect || undefined,
    component: () => import("/home/runner/work/portal2-frontend/portal2-frontend/pages/401.vue").then(m => m.default || m)
  },
  {
    path: accountiA8tX7zHzAMeta?.path ?? "/account",
    children: [
  {
    name: change_45passwordXJkAztILXHMeta?.name ?? "account-change-password",
    path: change_45passwordXJkAztILXHMeta?.path ?? "change-password",
    meta: change_45passwordXJkAztILXHMeta || {},
    alias: change_45passwordXJkAztILXHMeta?.alias || [],
    redirect: change_45passwordXJkAztILXHMeta?.redirect || undefined,
    component: () => import("/home/runner/work/portal2-frontend/portal2-frontend/pages/account/change-password.vue").then(m => m.default || m)
  },
  {
    name: indexAM1HeT8I7EMeta?.name ?? "account",
    path: indexAM1HeT8I7EMeta?.path ?? "",
    meta: indexAM1HeT8I7EMeta || {},
    alias: indexAM1HeT8I7EMeta?.alias || [],
    redirect: indexAM1HeT8I7EMeta?.redirect || undefined,
    component: () => import("/home/runner/work/portal2-frontend/portal2-frontend/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: notificationsLPc0GsfyhwMeta?.name ?? "account-notifications",
    path: notificationsLPc0GsfyhwMeta?.path ?? "notifications",
    meta: notificationsLPc0GsfyhwMeta || {},
    alias: notificationsLPc0GsfyhwMeta?.alias || [],
    redirect: notificationsLPc0GsfyhwMeta?.redirect || undefined,
    component: () => import("/home/runner/work/portal2-frontend/portal2-frontend/pages/account/notifications.vue").then(m => m.default || m)
  },
  {
    name: preferencesAyAvwQhEdQMeta?.name ?? "account-preferences",
    path: preferencesAyAvwQhEdQMeta?.path ?? "preferences",
    meta: preferencesAyAvwQhEdQMeta || {},
    alias: preferencesAyAvwQhEdQMeta?.alias || [],
    redirect: preferencesAyAvwQhEdQMeta?.redirect || undefined,
    component: () => import("/home/runner/work/portal2-frontend/portal2-frontend/pages/account/preferences.vue").then(m => m.default || m)
  }
],
    name: accountiA8tX7zHzAMeta?.name ?? undefined,
    meta: accountiA8tX7zHzAMeta || {},
    alias: accountiA8tX7zHzAMeta?.alias || [],
    redirect: accountiA8tX7zHzAMeta?.redirect || undefined,
    component: () => import("/home/runner/work/portal2-frontend/portal2-frontend/pages/account.vue").then(m => m.default || m)
  },
  {
    name: _91invoiceid_93sDe98PfSvMMeta?.name ?? "accounting-invoice-invoiceid",
    path: _91invoiceid_93sDe98PfSvMMeta?.path ?? "/accounting/invoice/:invoiceid()",
    meta: _91invoiceid_93sDe98PfSvMMeta || {},
    alias: _91invoiceid_93sDe98PfSvMMeta?.alias || [],
    redirect: _91invoiceid_93sDe98PfSvMMeta?.redirect || undefined,
    component: () => import("/home/runner/work/portal2-frontend/portal2-frontend/pages/accounting/invoice/[invoiceid].vue").then(m => m.default || m)
  },
  {
    name: _91postageid_93rPJ5kaRQs4Meta?.name ?? "accounting-invoice-serviceid-postageid",
    path: _91postageid_93rPJ5kaRQs4Meta?.path ?? "/accounting/invoice/:serviceid()/:postageid()",
    meta: _91postageid_93rPJ5kaRQs4Meta || {},
    alias: _91postageid_93rPJ5kaRQs4Meta?.alias || [],
    redirect: _91postageid_93rPJ5kaRQs4Meta?.redirect || undefined,
    component: () => import("/home/runner/work/portal2-frontend/portal2-frontend/pages/accounting/invoice/[serviceid]/[postageid].vue").then(m => m.default || m)
  },
  {
    name: indexHP1BF5rLryMeta?.name ?? "accounting-invoice",
    path: indexHP1BF5rLryMeta?.path ?? "/accounting/invoice",
    meta: indexHP1BF5rLryMeta || {},
    alias: indexHP1BF5rLryMeta?.alias || [],
    redirect: indexHP1BF5rLryMeta?.redirect || undefined,
    component: () => import("/home/runner/work/portal2-frontend/portal2-frontend/pages/accounting/invoice/index.vue").then(m => m.default || m)
  },
  {
    name: newinvoicebGpKziYCaFMeta?.name ?? "accounting-invoice-newinvoice",
    path: newinvoicebGpKziYCaFMeta?.path ?? "/accounting/invoice/newinvoice",
    meta: newinvoicebGpKziYCaFMeta || {},
    alias: newinvoicebGpKziYCaFMeta?.alias || [],
    redirect: newinvoicebGpKziYCaFMeta?.redirect || undefined,
    component: () => import("/home/runner/work/portal2-frontend/portal2-frontend/pages/accounting/invoice/newinvoice.vue").then(m => m.default || m)
  },
  {
    name: companyxArZtrXaIeMeta?.name ?? "auth-company",
    path: companyxArZtrXaIeMeta?.path ?? "/auth/company",
    meta: companyxArZtrXaIeMeta || {},
    alias: companyxArZtrXaIeMeta?.alias || [],
    redirect: companyxArZtrXaIeMeta?.redirect || undefined,
    component: () => import("/home/runner/work/portal2-frontend/portal2-frontend/pages/auth/company.vue").then(m => m.default || m)
  },
  {
    name: indexnlV6iX0qoHMeta?.name ?? "auth-confirm-email-email",
    path: indexnlV6iX0qoHMeta?.path ?? "/auth/confirm/email/:email()",
    meta: indexnlV6iX0qoHMeta || {},
    alias: indexnlV6iX0qoHMeta?.alias || [],
    redirect: indexnlV6iX0qoHMeta?.redirect || undefined,
    component: () => import("/home/runner/work/portal2-frontend/portal2-frontend/pages/auth/confirm/email/[email]/index.vue").then(m => m.default || m)
  },
  {
    name: indext5JuSlxLEHMeta?.name ?? "auth-confirm",
    path: indext5JuSlxLEHMeta?.path ?? "/auth/confirm",
    meta: indext5JuSlxLEHMeta || {},
    alias: indext5JuSlxLEHMeta?.alias || [],
    redirect: indext5JuSlxLEHMeta?.redirect || undefined,
    component: () => import("/home/runner/work/portal2-frontend/portal2-frontend/pages/auth/confirm/index.vue").then(m => m.default || m)
  },
  {
    name: indexQcRMlBhBIrMeta?.name ?? "auth-reset-password-email-email-code-code",
    path: indexQcRMlBhBIrMeta?.path ?? "/auth/reset-password/email/:email()/code/:code()",
    meta: indexQcRMlBhBIrMeta || {},
    alias: indexQcRMlBhBIrMeta?.alias || [],
    redirect: indexQcRMlBhBIrMeta?.redirect || undefined,
    component: () => import("/home/runner/work/portal2-frontend/portal2-frontend/pages/auth/reset-password/email/[email]/code/[code]/index.vue").then(m => m.default || m)
  },
  {
    name: indexO2zWEAP92lMeta?.name ?? "auth-reset-password-email-email",
    path: indexO2zWEAP92lMeta?.path ?? "/auth/reset-password/email/:email()",
    meta: indexO2zWEAP92lMeta || {},
    alias: indexO2zWEAP92lMeta?.alias || [],
    redirect: indexO2zWEAP92lMeta?.redirect || undefined,
    component: () => import("/home/runner/work/portal2-frontend/portal2-frontend/pages/auth/reset-password/email/[email]/index.vue").then(m => m.default || m)
  },
  {
    name: indexkqq8cNW3VLMeta?.name ?? "auth-reset-password",
    path: indexkqq8cNW3VLMeta?.path ?? "/auth/reset-password",
    meta: indexkqq8cNW3VLMeta || {},
    alias: indexkqq8cNW3VLMeta?.alias || [],
    redirect: indexkqq8cNW3VLMeta?.redirect || undefined,
    component: () => import("/home/runner/work/portal2-frontend/portal2-frontend/pages/auth/reset-password/index.vue").then(m => m.default || m)
  },
  {
    name: campaignsSQ2Sx6oUh4Meta?.name ?? "campaigns",
    path: campaignsSQ2Sx6oUh4Meta?.path ?? "/campaigns",
    meta: campaignsSQ2Sx6oUh4Meta || {},
    alias: campaignsSQ2Sx6oUh4Meta?.alias || [],
    redirect: campaignsSQ2Sx6oUh4Meta?.redirect || undefined,
    component: () => import("/home/runner/work/portal2-frontend/portal2-frontend/pages/campaigns.vue").then(m => m.default || m)
  },
  {
    name: campaignSupportj7IQMj2GMXMeta?.name ?? "campaignSupport",
    path: campaignSupportj7IQMj2GMXMeta?.path ?? "/campaignSupport",
    meta: campaignSupportj7IQMj2GMXMeta || {},
    alias: campaignSupportj7IQMj2GMXMeta?.alias || [],
    redirect: campaignSupportj7IQMj2GMXMeta?.redirect || undefined,
    component: () => import("/home/runner/work/portal2-frontend/portal2-frontend/pages/campaignSupport.vue").then(m => m.default || m)
  },
  {
    path: companyAuRePIrUpeMeta?.path ?? "/company",
    children: [
  {
    name: _91folderName_9392y8yW3K2yMeta?.name ?? "company-companyId-assets-folderName",
    path: _91folderName_9392y8yW3K2yMeta?.path ?? ":companyId()/assets/:folderName()",
    meta: _91folderName_9392y8yW3K2yMeta || {},
    alias: _91folderName_9392y8yW3K2yMeta?.alias || [],
    redirect: _91folderName_9392y8yW3K2yMeta?.redirect || undefined,
    component: () => import("/home/runner/work/portal2-frontend/portal2-frontend/pages/company/[companyId]/assets/[folderName].vue").then(m => m.default || m)
  },
  {
    name: indexyitrjj7pS5Meta?.name ?? "company-companyId-assets",
    path: indexyitrjj7pS5Meta?.path ?? ":companyId()/assets",
    meta: indexyitrjj7pS5Meta || {},
    alias: indexyitrjj7pS5Meta?.alias || [],
    redirect: indexyitrjj7pS5Meta?.redirect || undefined,
    component: () => import("/home/runner/work/portal2-frontend/portal2-frontend/pages/company/[companyId]/assets/index.vue").then(m => m.default || m)
  },
  {
    name: billingKCHhEFiEpHMeta?.name ?? "company-companyId-billing",
    path: billingKCHhEFiEpHMeta?.path ?? ":companyId()/billing",
    meta: billingKCHhEFiEpHMeta || {},
    alias: billingKCHhEFiEpHMeta?.alias || [],
    redirect: billingKCHhEFiEpHMeta?.redirect || undefined,
    component: () => import("/home/runner/work/portal2-frontend/portal2-frontend/pages/company/[companyId]/billing.vue").then(m => m.default || m)
  },
  {
    name: indexEn74J9ghxNMeta?.name ?? "company-companyId-campaigns-campaignId",
    path: indexEn74J9ghxNMeta?.path ?? ":companyId()/campaigns/:campaignId()",
    meta: indexEn74J9ghxNMeta || {},
    alias: indexEn74J9ghxNMeta?.alias || [],
    redirect: indexEn74J9ghxNMeta?.redirect || undefined,
    component: () => import("/home/runner/work/portal2-frontend/portal2-frontend/pages/company/[companyId]/campaigns/[campaignId]/index.vue").then(m => m.default || m)
  },
  {
    name: createAycqmafRMeMeta?.name ?? "company-companyId-campaigns-create",
    path: createAycqmafRMeMeta?.path ?? ":companyId()/campaigns/create",
    meta: createAycqmafRMeMeta || {},
    alias: createAycqmafRMeMeta?.alias || [],
    redirect: createAycqmafRMeMeta?.redirect || undefined,
    component: () => import("/home/runner/work/portal2-frontend/portal2-frontend/pages/company/[companyId]/campaigns/create.vue").then(m => m.default || m)
  },
  {
    name: indexvC92XIce6PMeta?.name ?? "company-companyId-campaigns",
    path: indexvC92XIce6PMeta?.path ?? ":companyId()/campaigns",
    meta: indexvC92XIce6PMeta || {},
    alias: indexvC92XIce6PMeta?.alias || [],
    redirect: indexvC92XIce6PMeta?.redirect || undefined,
    component: () => import("/home/runner/work/portal2-frontend/portal2-frontend/pages/company/[companyId]/campaigns/index.vue").then(m => m.default || m)
  },
  {
    name: campaignSupportTjTmMfI9twMeta?.name ?? "company-companyId-campaignSupport",
    path: campaignSupportTjTmMfI9twMeta?.path ?? ":companyId()/campaignSupport",
    meta: campaignSupportTjTmMfI9twMeta || {},
    alias: campaignSupportTjTmMfI9twMeta?.alias || [],
    redirect: campaignSupportTjTmMfI9twMeta?.redirect || undefined,
    component: () => import("/home/runner/work/portal2-frontend/portal2-frontend/pages/company/[companyId]/campaignSupport.vue").then(m => m.default || m)
  },
  {
    name: campaignSupports7ZofAqOQDcMeta?.name ?? "company-companyId-campaignSupports",
    path: campaignSupports7ZofAqOQDcMeta?.path ?? ":companyId()/campaignSupports",
    meta: campaignSupports7ZofAqOQDcMeta || {},
    alias: campaignSupports7ZofAqOQDcMeta?.alias || [],
    redirect: campaignSupports7ZofAqOQDcMeta?.redirect || undefined,
    component: () => import("/home/runner/work/portal2-frontend/portal2-frontend/pages/company/[companyId]/campaignSupports.vue").then(m => m.default || m)
  },
  {
    name: creditsTpWqV4Qu1tMeta?.name ?? "company-companyId-credits",
    path: creditsTpWqV4Qu1tMeta?.path ?? ":companyId()/credits",
    meta: creditsTpWqV4Qu1tMeta || {},
    alias: creditsTpWqV4Qu1tMeta?.alias || [],
    redirect: creditsTpWqV4Qu1tMeta?.redirect || undefined,
    component: () => import("/home/runner/work/portal2-frontend/portal2-frontend/pages/company/[companyId]/credits.vue").then(m => m.default || m)
  },
  {
    name: discountsBqDGpwt3qMMeta?.name ?? "company-companyId-discounts",
    path: discountsBqDGpwt3qMMeta?.path ?? ":companyId()/discounts",
    meta: discountsBqDGpwt3qMMeta || {},
    alias: discountsBqDGpwt3qMMeta?.alias || [],
    redirect: discountsBqDGpwt3qMMeta?.redirect || undefined,
    component: () => import("/home/runner/work/portal2-frontend/portal2-frontend/pages/company/[companyId]/discounts.vue").then(m => m.default || m)
  },
  {
    name: historyGQInVQOV9JMeta?.name ?? "company-companyId-history",
    path: historyGQInVQOV9JMeta?.path ?? ":companyId()/history",
    meta: historyGQInVQOV9JMeta || {},
    alias: historyGQInVQOV9JMeta?.alias || [],
    redirect: historyGQInVQOV9JMeta?.redirect || undefined,
    component: () => import("/home/runner/work/portal2-frontend/portal2-frontend/pages/company/[companyId]/history.vue").then(m => m.default || m)
  },
  {
    name: indexsdesMEjlzoMeta?.name ?? "company-companyId",
    path: indexsdesMEjlzoMeta?.path ?? ":companyId()",
    meta: indexsdesMEjlzoMeta || {},
    alias: indexsdesMEjlzoMeta?.alias || [],
    redirect: indexsdesMEjlzoMeta?.redirect || undefined,
    component: () => import("/home/runner/work/portal2-frontend/portal2-frontend/pages/company/[companyId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexFWOffo7bEnMeta?.name ?? "company-companyId-lists-listId-file-listFileId",
    path: indexFWOffo7bEnMeta?.path ?? ":companyId()/lists/:listId()/file/:listFileId()",
    meta: indexFWOffo7bEnMeta || {},
    alias: indexFWOffo7bEnMeta?.alias || [],
    redirect: indexFWOffo7bEnMeta?.redirect || undefined,
    component: () => import("/home/runner/work/portal2-frontend/portal2-frontend/pages/company/[companyId]/lists/[listId]/file/[listFileId]/index.vue").then(m => m.default || m)
  },
  {
    name: resultsIbEAWtkcSpMeta?.name ?? "company-companyId-lists-listId-file-listFileId-results",
    path: resultsIbEAWtkcSpMeta?.path ?? ":companyId()/lists/:listId()/file/:listFileId()/results",
    meta: resultsIbEAWtkcSpMeta || {},
    alias: resultsIbEAWtkcSpMeta?.alias || [],
    redirect: resultsIbEAWtkcSpMeta?.redirect || undefined,
    component: () => import("/home/runner/work/portal2-frontend/portal2-frontend/pages/company/[companyId]/lists/[listId]/file/[listFileId]/results.vue").then(m => m.default || m)
  },
  {
    name: indexPk1pzpWkU4Meta?.name ?? "company-companyId-lists-listId",
    path: indexPk1pzpWkU4Meta?.path ?? ":companyId()/lists/:listId()",
    meta: indexPk1pzpWkU4Meta || {},
    alias: indexPk1pzpWkU4Meta?.alias || [],
    redirect: indexPk1pzpWkU4Meta?.redirect || undefined,
    component: () => import("/home/runner/work/portal2-frontend/portal2-frontend/pages/company/[companyId]/lists/[listId]/index.vue").then(m => m.default || m)
  },
  {
    name: uploadeoQl8dKBk9Meta?.name ?? "company-companyId-lists-listId-upload",
    path: uploadeoQl8dKBk9Meta?.path ?? ":companyId()/lists/:listId()/upload",
    meta: uploadeoQl8dKBk9Meta || {},
    alias: uploadeoQl8dKBk9Meta?.alias || [],
    redirect: uploadeoQl8dKBk9Meta?.redirect || undefined,
    component: () => import("/home/runner/work/portal2-frontend/portal2-frontend/pages/company/[companyId]/lists/[listId]/upload.vue").then(m => m.default || m)
  },
  {
    name: createCTg7S0RhFUMeta?.name ?? "company-companyId-lists-create",
    path: createCTg7S0RhFUMeta?.path ?? ":companyId()/lists/create",
    meta: createCTg7S0RhFUMeta || {},
    alias: createCTg7S0RhFUMeta?.alias || [],
    redirect: createCTg7S0RhFUMeta?.redirect || undefined,
    component: () => import("/home/runner/work/portal2-frontend/portal2-frontend/pages/company/[companyId]/lists/create.vue").then(m => m.default || m)
  },
  {
    name: index6cQkwK7w8qMeta?.name ?? "company-companyId-lists",
    path: index6cQkwK7w8qMeta?.path ?? ":companyId()/lists",
    meta: index6cQkwK7w8qMeta || {},
    alias: index6cQkwK7w8qMeta?.alias || [],
    redirect: index6cQkwK7w8qMeta?.redirect || undefined,
    component: () => import("/home/runner/work/portal2-frontend/portal2-frontend/pages/company/[companyId]/lists/index.vue").then(m => m.default || m)
  },
  {
    path: locationsPyiNH0SfRCMeta?.path ?? ":companyId()/locations",
    children: [
  {
    name: index84xc2K066oMeta?.name ?? "company-companyId-locations-locationId",
    path: index84xc2K066oMeta?.path ?? ":locationId()",
    meta: index84xc2K066oMeta || {},
    alias: index84xc2K066oMeta?.alias || [],
    redirect: index84xc2K066oMeta?.redirect || undefined,
    component: () => import("/home/runner/work/portal2-frontend/portal2-frontend/pages/company/[companyId]/locations/[locationId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexaI4L7xSDhjMeta?.name ?? "company-companyId-locations",
    path: indexaI4L7xSDhjMeta?.path ?? "",
    meta: indexaI4L7xSDhjMeta || {},
    alias: indexaI4L7xSDhjMeta?.alias || [],
    redirect: indexaI4L7xSDhjMeta?.redirect || undefined,
    component: () => import("/home/runner/work/portal2-frontend/portal2-frontend/pages/company/[companyId]/locations/index.vue").then(m => m.default || m)
  }
],
    name: locationsPyiNH0SfRCMeta?.name ?? undefined,
    meta: locationsPyiNH0SfRCMeta || {},
    alias: locationsPyiNH0SfRCMeta?.alias || [],
    redirect: locationsPyiNH0SfRCMeta?.redirect || undefined,
    component: () => import("/home/runner/work/portal2-frontend/portal2-frontend/pages/company/[companyId]/locations.vue").then(m => m.default || m)
  },
  {
    name: settingsvYn7oXJITQMeta?.name ?? "company-companyId-settings",
    path: settingsvYn7oXJITQMeta?.path ?? ":companyId()/settings",
    meta: settingsvYn7oXJITQMeta || {},
    alias: settingsvYn7oXJITQMeta?.alias || [],
    redirect: settingsvYn7oXJITQMeta?.redirect || undefined,
    component: () => import("/home/runner/work/portal2-frontend/portal2-frontend/pages/company/[companyId]/settings.vue").then(m => m.default || m)
  },
  {
    path: users3qyHyfvhTOMeta?.path ?? ":companyId()/users",
    children: [
  {
    name: indexfy3AvcgAURMeta?.name ?? "company-companyId-users",
    path: indexfy3AvcgAURMeta?.path ?? "",
    meta: indexfy3AvcgAURMeta || {},
    alias: indexfy3AvcgAURMeta?.alias || [],
    redirect: indexfy3AvcgAURMeta?.redirect || undefined,
    component: () => import("/home/runner/work/portal2-frontend/portal2-frontend/pages/company/[companyId]/users/index.vue").then(m => m.default || m)
  }
],
    name: users3qyHyfvhTOMeta?.name ?? undefined,
    meta: users3qyHyfvhTOMeta || {},
    alias: users3qyHyfvhTOMeta?.alias || [],
    redirect: users3qyHyfvhTOMeta?.redirect || undefined,
    component: () => import("/home/runner/work/portal2-frontend/portal2-frontend/pages/company/[companyId]/users.vue").then(m => m.default || m)
  },
  {
    name: create1bHsoA9rkQMeta?.name ?? "company-create",
    path: create1bHsoA9rkQMeta?.path ?? "create",
    meta: create1bHsoA9rkQMeta || {},
    alias: create1bHsoA9rkQMeta?.alias || [],
    redirect: create1bHsoA9rkQMeta?.redirect || undefined,
    component: () => import("/home/runner/work/portal2-frontend/portal2-frontend/pages/company/create.vue").then(m => m.default || m)
  },
  {
    name: indexKNIQeTNKuHMeta?.name ?? "company",
    path: indexKNIQeTNKuHMeta?.path ?? "",
    meta: indexKNIQeTNKuHMeta || {},
    alias: indexKNIQeTNKuHMeta?.alias || [],
    redirect: indexKNIQeTNKuHMeta?.redirect || undefined,
    component: () => import("/home/runner/work/portal2-frontend/portal2-frontend/pages/company/index.vue").then(m => m.default || m)
  }
],
    name: companyAuRePIrUpeMeta?.name ?? undefined,
    meta: companyAuRePIrUpeMeta || {},
    alias: companyAuRePIrUpeMeta?.alias || [],
    redirect: companyAuRePIrUpeMeta?.redirect || undefined,
    component: () => import("/home/runner/work/portal2-frontend/portal2-frontend/pages/company.vue").then(m => m.default || m)
  },
  {
    name: faqTfHNXIdp0DMeta?.name ?? "faq",
    path: faqTfHNXIdp0DMeta?.path ?? "/faq",
    meta: faqTfHNXIdp0DMeta || {},
    alias: faqTfHNXIdp0DMeta?.alias || [],
    redirect: faqTfHNXIdp0DMeta?.redirect || undefined,
    component: () => import("/home/runner/work/portal2-frontend/portal2-frontend/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: forgotPasswordxDthygcoYfMeta?.name ?? "forgotPassword",
    path: forgotPasswordxDthygcoYfMeta?.path ?? "/forgotPassword",
    meta: forgotPasswordxDthygcoYfMeta || {},
    alias: forgotPasswordxDthygcoYfMeta?.alias || [],
    redirect: forgotPasswordxDthygcoYfMeta?.redirect || undefined,
    component: () => import("/home/runner/work/portal2-frontend/portal2-frontend/pages/forgotPassword.vue").then(m => m.default || m)
  },
  {
    name: indexkjDU6sW8zfMeta?.name ?? "index",
    path: indexkjDU6sW8zfMeta?.path ?? "/",
    meta: indexkjDU6sW8zfMeta || {},
    alias: indexkjDU6sW8zfMeta?.alias || [],
    redirect: indexkjDU6sW8zfMeta?.redirect || undefined,
    component: () => import("/home/runner/work/portal2-frontend/portal2-frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: lists9bZHGmCfWVMeta?.name ?? "lists",
    path: lists9bZHGmCfWVMeta?.path ?? "/lists",
    meta: lists9bZHGmCfWVMeta || {},
    alias: lists9bZHGmCfWVMeta?.alias || [],
    redirect: lists9bZHGmCfWVMeta?.redirect || undefined,
    component: () => import("/home/runner/work/portal2-frontend/portal2-frontend/pages/lists.vue").then(m => m.default || m)
  },
  {
    name: loginhmR34h9WdkMeta?.name ?? "login",
    path: loginhmR34h9WdkMeta?.path ?? "/login",
    meta: loginhmR34h9WdkMeta || {},
    alias: loginhmR34h9WdkMeta?.alias || [],
    redirect: loginhmR34h9WdkMeta?.redirect || undefined,
    component: () => import("/home/runner/work/portal2-frontend/portal2-frontend/pages/login.vue").then(m => m.default || m)
  },
  {
    name: privacyJJfR35u8BuMeta?.name ?? "privacy",
    path: privacyJJfR35u8BuMeta?.path ?? "/privacy",
    meta: privacyJJfR35u8BuMeta || {},
    alias: privacyJJfR35u8BuMeta?.alias || [],
    redirect: privacyJJfR35u8BuMeta?.redirect || undefined,
    component: () => import("/home/runner/work/portal2-frontend/portal2-frontend/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: indexJzQxqcWzHaMeta?.name ?? "products-productid",
    path: indexJzQxqcWzHaMeta?.path ?? "/products/:productid()",
    meta: indexJzQxqcWzHaMeta || {},
    alias: indexJzQxqcWzHaMeta?.alias || [],
    redirect: indexJzQxqcWzHaMeta?.redirect || undefined,
    component: () => import("/home/runner/work/portal2-frontend/portal2-frontend/pages/products/[productid]/index.vue").then(m => m.default || m)
  },
  {
    name: categoriesKffWUczpbtMeta?.name ?? "products-categories",
    path: categoriesKffWUczpbtMeta?.path ?? "/products/categories",
    meta: categoriesKffWUczpbtMeta || {},
    alias: categoriesKffWUczpbtMeta?.alias || [],
    redirect: categoriesKffWUczpbtMeta?.redirect || undefined,
    component: () => import("/home/runner/work/portal2-frontend/portal2-frontend/pages/products/categories.vue").then(m => m.default || m)
  },
  {
    name: envelopesDVOVOGee5wMeta?.name ?? "products-envelopes",
    path: envelopesDVOVOGee5wMeta?.path ?? "/products/envelopes",
    meta: envelopesDVOVOGee5wMeta || {},
    alias: envelopesDVOVOGee5wMeta?.alias || [],
    redirect: envelopesDVOVOGee5wMeta?.redirect || undefined,
    component: () => import("/home/runner/work/portal2-frontend/portal2-frontend/pages/products/envelopes.vue").then(m => m.default || m)
  },
  {
    name: indexxZHcSBR8E3Meta?.name ?? "products",
    path: indexxZHcSBR8E3Meta?.path ?? "/products",
    meta: indexxZHcSBR8E3Meta || {},
    alias: indexxZHcSBR8E3Meta?.alias || [],
    redirect: indexxZHcSBR8E3Meta?.redirect || undefined,
    component: () => import("/home/runner/work/portal2-frontend/portal2-frontend/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: letterShop0W7jUCJkKTMeta?.name ?? "products-letterShop",
    path: letterShop0W7jUCJkKTMeta?.path ?? "/products/letterShop",
    meta: letterShop0W7jUCJkKTMeta || {},
    alias: letterShop0W7jUCJkKTMeta?.alias || [],
    redirect: letterShop0W7jUCJkKTMeta?.redirect || undefined,
    component: () => import("/home/runner/work/portal2-frontend/portal2-frontend/pages/products/letterShop.vue").then(m => m.default || m)
  },
  {
    name: paperStock5CX0M3nfdSMeta?.name ?? "products-paperStock",
    path: paperStock5CX0M3nfdSMeta?.path ?? "/products/paperStock",
    meta: paperStock5CX0M3nfdSMeta || {},
    alias: paperStock5CX0M3nfdSMeta?.alias || [],
    redirect: paperStock5CX0M3nfdSMeta?.redirect || undefined,
    component: () => import("/home/runner/work/portal2-frontend/portal2-frontend/pages/products/paperStock.vue").then(m => m.default || m)
  },
  {
    name: productHistoryejNffZYlLKMeta?.name ?? "products-productHistory",
    path: productHistoryejNffZYlLKMeta?.path ?? "/products/productHistory",
    meta: productHistoryejNffZYlLKMeta || {},
    alias: productHistoryejNffZYlLKMeta?.alias || [],
    redirect: productHistoryejNffZYlLKMeta?.redirect || undefined,
    component: () => import("/home/runner/work/portal2-frontend/portal2-frontend/pages/products/productHistory.vue").then(m => m.default || m)
  },
  {
    name: registeroQ9uB8TWNHMeta?.name ?? "register",
    path: registeroQ9uB8TWNHMeta?.path ?? "/register",
    meta: registeroQ9uB8TWNHMeta || {},
    alias: registeroQ9uB8TWNHMeta?.alias || [],
    redirect: registeroQ9uB8TWNHMeta?.redirect || undefined,
    component: () => import("/home/runner/work/portal2-frontend/portal2-frontend/pages/register.vue").then(m => m.default || m)
  },
  {
    name: indexyXNwuXzzaYMeta?.name ?? "reports",
    path: indexyXNwuXzzaYMeta?.path ?? "/reports",
    meta: indexyXNwuXzzaYMeta || {},
    alias: indexyXNwuXzzaYMeta?.alias || [],
    redirect: indexyXNwuXzzaYMeta?.redirect || undefined,
    component: () => import("/home/runner/work/portal2-frontend/portal2-frontend/pages/reports/index.vue").then(m => m.default || m)
  },
  {
    name: brandsgr4HO3MRELMeta?.name ?? "settings-brands",
    path: brandsgr4HO3MRELMeta?.path ?? "/settings/brands",
    meta: brandsgr4HO3MRELMeta || {},
    alias: brandsgr4HO3MRELMeta?.alias || [],
    redirect: brandsgr4HO3MRELMeta?.redirect || undefined,
    component: () => import("/home/runner/work/portal2-frontend/portal2-frontend/pages/settings/brands.vue").then(m => m.default || m)
  },
  {
    name: indexcvvqtO5YChMeta?.name ?? "settings-campaignType-campaignTypeId",
    path: indexcvvqtO5YChMeta?.path ?? "/settings/campaignType/:campaignTypeId()",
    meta: indexcvvqtO5YChMeta || {},
    alias: indexcvvqtO5YChMeta?.alias || [],
    redirect: indexcvvqtO5YChMeta?.redirect || undefined,
    component: () => import("/home/runner/work/portal2-frontend/portal2-frontend/pages/settings/campaignType/[campaignTypeId]/index.vue").then(m => m.default || m)
  },
  {
    name: index1n8J2GVuX3Meta?.name ?? "settings-campaignType",
    path: index1n8J2GVuX3Meta?.path ?? "/settings/campaignType",
    meta: index1n8J2GVuX3Meta || {},
    alias: index1n8J2GVuX3Meta?.alias || [],
    redirect: index1n8J2GVuX3Meta?.redirect || undefined,
    component: () => import("/home/runner/work/portal2-frontend/portal2-frontend/pages/settings/campaignType/index.vue").then(m => m.default || m)
  },
  {
    name: creditsvSggMupk5fMeta?.name ?? "settings-credits",
    path: creditsvSggMupk5fMeta?.path ?? "/settings/credits",
    meta: creditsvSggMupk5fMeta || {},
    alias: creditsvSggMupk5fMeta?.alias || [],
    redirect: creditsvSggMupk5fMeta?.redirect || undefined,
    component: () => import("/home/runner/work/portal2-frontend/portal2-frontend/pages/settings/credits.vue").then(m => m.default || m)
  },
  {
    name: discountsHdA58cFX32Meta?.name ?? "settings-discounts",
    path: discountsHdA58cFX32Meta?.path ?? "/settings/discounts",
    meta: discountsHdA58cFX32Meta || {},
    alias: discountsHdA58cFX32Meta?.alias || [],
    redirect: discountsHdA58cFX32Meta?.redirect || undefined,
    component: () => import("/home/runner/work/portal2-frontend/portal2-frontend/pages/settings/discounts.vue").then(m => m.default || m)
  },
  {
    name: index1FI7MCilt2Meta?.name ?? "settings",
    path: index1FI7MCilt2Meta?.path ?? "/settings",
    meta: index1FI7MCilt2Meta || {},
    alias: index1FI7MCilt2Meta?.alias || [],
    redirect: index1FI7MCilt2Meta?.redirect || undefined,
    component: () => import("/home/runner/work/portal2-frontend/portal2-frontend/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: jobIntegrationsKqZw0Yi6niMeta?.name ?? "settings-jobIntegrations",
    path: jobIntegrationsKqZw0Yi6niMeta?.path ?? "/settings/jobIntegrations",
    meta: jobIntegrationsKqZw0Yi6niMeta || {},
    alias: jobIntegrationsKqZw0Yi6niMeta?.alias || [],
    redirect: jobIntegrationsKqZw0Yi6niMeta?.redirect || undefined,
    component: () => import("/home/runner/work/portal2-frontend/portal2-frontend/pages/settings/jobIntegrations.vue").then(m => m.default || m)
  },
  {
    name: supportEF6SwxkgDpMeta?.name ?? "support",
    path: supportEF6SwxkgDpMeta?.path ?? "/support",
    meta: supportEF6SwxkgDpMeta || {},
    alias: supportEF6SwxkgDpMeta?.alias || [],
    redirect: supportEF6SwxkgDpMeta?.redirect || undefined,
    component: () => import("/home/runner/work/portal2-frontend/portal2-frontend/pages/support.vue").then(m => m.default || m)
  },
  {
    name: termsCmQpDs8HqQMeta?.name ?? "terms",
    path: termsCmQpDs8HqQMeta?.path ?? "/terms",
    meta: termsCmQpDs8HqQMeta || {},
    alias: termsCmQpDs8HqQMeta?.alias || [],
    redirect: termsCmQpDs8HqQMeta?.redirect || undefined,
    component: () => import("/home/runner/work/portal2-frontend/portal2-frontend/pages/terms.vue").then(m => m.default || m)
  },
  {
    path: users8AaY5h6Z4qMeta?.path ?? "/users",
    children: [
  {
    name: clientwEeptjs88iMeta?.name ?? "users-client",
    path: clientwEeptjs88iMeta?.path ?? "client",
    meta: clientwEeptjs88iMeta || {},
    alias: clientwEeptjs88iMeta?.alias || [],
    redirect: clientwEeptjs88iMeta?.redirect || undefined,
    component: () => import("/home/runner/work/portal2-frontend/portal2-frontend/pages/users/client.vue").then(m => m.default || m)
  },
  {
    name: indexButVxqY1YhMeta?.name ?? "users",
    path: indexButVxqY1YhMeta?.path ?? "",
    meta: indexButVxqY1YhMeta || {},
    alias: indexButVxqY1YhMeta?.alias || [],
    redirect: indexButVxqY1YhMeta?.redirect || undefined,
    component: () => import("/home/runner/work/portal2-frontend/portal2-frontend/pages/users/index.vue").then(m => m.default || m)
  },
  {
    name: internalZs2aMpzH39Meta?.name ?? "users-internal",
    path: internalZs2aMpzH39Meta?.path ?? "internal",
    meta: internalZs2aMpzH39Meta || {},
    alias: internalZs2aMpzH39Meta?.alias || [],
    redirect: internalZs2aMpzH39Meta?.redirect || undefined,
    component: () => import("/home/runner/work/portal2-frontend/portal2-frontend/pages/users/internal.vue").then(m => m.default || m)
  }
],
    name: users8AaY5h6Z4qMeta?.name ?? undefined,
    meta: users8AaY5h6Z4qMeta || {},
    alias: users8AaY5h6Z4qMeta?.alias || [],
    redirect: users8AaY5h6Z4qMeta?.redirect || undefined,
    component: () => import("/home/runner/work/portal2-frontend/portal2-frontend/pages/users.vue").then(m => m.default || m)
  },
  {
    name: versionKeC3GHNQEuMeta?.name ?? "version",
    path: versionKeC3GHNQEuMeta?.path ?? "/version",
    meta: versionKeC3GHNQEuMeta || {},
    alias: versionKeC3GHNQEuMeta?.alias || [],
    redirect: versionKeC3GHNQEuMeta?.redirect || undefined,
    component: () => import("/home/runner/work/portal2-frontend/portal2-frontend/pages/version.vue").then(m => m.default || m)
  }
]