
import { defineNuxtPlugin, useRuntimeConfig } from '#imports';
import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';
import BadgeDirective from 'primevue/badgedirective';
import Tooltip from 'primevue/tooltip';
import Ripple from 'primevue/ripple';
import StyleClass from 'primevue/styleclass';
import FocusTrap from 'primevue/focustrap';
import AnimateOnScroll from 'primevue/animateonscroll';
import PrimePassthrough from '/home/runner/work/portal2-frontend/portal2-frontend/assets/presets/primePassthrough.ts';


export default defineNuxtPlugin(({ vueApp }) => {
  const runtimeConfig = useRuntimeConfig();
  const config = runtimeConfig?.public?.primevue ?? {};
  const { usePrimeVue = true, options = {} } = config;
  const pt = { pt: PrimePassthrough };

  usePrimeVue && vueApp.use(PrimeVue, { ...options, ...pt });
  vueApp.use(ToastService);
  vueApp.directive('primebadge', BadgeDirective);
vueApp.directive('primetooltip', Tooltip);
vueApp.directive('primeripple', Ripple);
vueApp.directive('primestyleclass', StyleClass);
vueApp.directive('primefocustrap', FocusTrap);
vueApp.directive('primeanimateonscroll', AnimateOnScroll);
});
        