import revive_payload_client_FUB73TM4su from "/home/runner/work/portal2-frontend/portal2-frontend/node_modules/.pnpm/nuxt@3.9.0_@types+node@18.19.4_eslint@8.56.0_typescript@5.3.3_vite@5.2.13/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_PpCG9Uh13z from "/home/runner/work/portal2-frontend/portal2-frontend/node_modules/.pnpm/nuxt@3.9.0_@types+node@18.19.4_eslint@8.56.0_typescript@5.3.3_vite@5.2.13/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jOmhObjHnq from "/home/runner/work/portal2-frontend/portal2-frontend/node_modules/.pnpm/nuxt@3.9.0_@types+node@18.19.4_eslint@8.56.0_typescript@5.3.3_vite@5.2.13/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_br2HNE1gM9 from "/home/runner/work/portal2-frontend/portal2-frontend/node_modules/.pnpm/nuxt@3.9.0_@types+node@18.19.4_eslint@8.56.0_typescript@5.3.3_vite@5.2.13/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_BKR7nbvoZz from "/home/runner/work/portal2-frontend/portal2-frontend/node_modules/.pnpm/nuxt@3.9.0_@types+node@18.19.4_eslint@8.56.0_typescript@5.3.3_vite@5.2.13/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_8But6JuXAF from "/home/runner/work/portal2-frontend/portal2-frontend/node_modules/.pnpm/@pinia+nuxt@0.5.1_typescript@5.3.3_vue@3.4.4/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/runner/work/portal2-frontend/portal2-frontend/.nuxt/components.plugin.mjs";
import prefetch_client_cUlNNA4znB from "/home/runner/work/portal2-frontend/portal2-frontend/node_modules/.pnpm/nuxt@3.9.0_@types+node@18.19.4_eslint@8.56.0_typescript@5.3.3_vite@5.2.13/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_EbXMFKiN0h from "/home/runner/work/portal2-frontend/portal2-frontend/node_modules/.pnpm/nuxt-bugsnag@7.4.0-beta.0_vue-router@4.2.5/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.js";
import primevue_plugin_egKpok8Auk from "/home/runner/work/portal2-frontend/portal2-frontend/.nuxt/primevue-plugin.mjs";
import plugin_client_CVCITR2Ygb from "/home/runner/work/portal2-frontend/portal2-frontend/node_modules/.pnpm/nuxt-primevue@0.2.0_vue@3.4.4/node_modules/nuxt-primevue/dist/runtime/plugin.client.mjs";
import chunk_reload_client_Dp5i3K60S3 from "/home/runner/work/portal2-frontend/portal2-frontend/node_modules/.pnpm/nuxt@3.9.0_@types+node@18.19.4_eslint@8.56.0_typescript@5.3.3_vite@5.2.13/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _00_logger_client_lOQlgMMdSS from "/home/runner/work/portal2-frontend/portal2-frontend/plugins/00.logger.client.ts";
import _01_auth_client_UOBMAirzzr from "/home/runner/work/portal2-frontend/portal2-frontend/plugins/01.auth.client.ts";
import _02_api_client_XzPc4Tu48d from "/home/runner/work/portal2-frontend/portal2-frontend/plugins/02.api.client.ts";
import _03_primetoast_client_LJtedLVtzk from "/home/runner/work/portal2-frontend/portal2-frontend/plugins/03.primetoast.client.ts";
import _04_rbac_client_g0YdBjmGaR from "/home/runner/work/portal2-frontend/portal2-frontend/plugins/04.rbac.client.ts";
import _05_amplifyhub_client_yr05Dd4iLN from "/home/runner/work/portal2-frontend/portal2-frontend/plugins/05.amplifyhub.client.ts";
import _06_vue_masonry_wall_3gxUEZUPQL from "/home/runner/work/portal2-frontend/portal2-frontend/plugins/06.vue-masonry-wall.ts";
import _07_websocket_client_I4vnkedcMC from "/home/runner/work/portal2-frontend/portal2-frontend/plugins/07.websocket.client.ts";
import _99_sharedFunctions_client_GcMZueS7iU from "/home/runner/work/portal2-frontend/portal2-frontend/plugins/99.sharedFunctions.client.ts";
export default [
  revive_payload_client_FUB73TM4su,
  unhead_PpCG9Uh13z,
  router_jOmhObjHnq,
  payload_client_br2HNE1gM9,
  check_outdated_build_client_BKR7nbvoZz,
  plugin_vue3_8But6JuXAF,
  components_plugin_KR1HBZs4kY,
  prefetch_client_cUlNNA4znB,
  plugin_EbXMFKiN0h,
  primevue_plugin_egKpok8Auk,
  plugin_client_CVCITR2Ygb,
  chunk_reload_client_Dp5i3K60S3,
  _00_logger_client_lOQlgMMdSS,
  _01_auth_client_UOBMAirzzr,
  _02_api_client_XzPc4Tu48d,
  _03_primetoast_client_LJtedLVtzk,
  _04_rbac_client_g0YdBjmGaR,
  _05_amplifyhub_client_yr05Dd4iLN,
  _06_vue_masonry_wall_3gxUEZUPQL,
  _07_websocket_client_I4vnkedcMC,
  _99_sharedFunctions_client_GcMZueS7iU
]